import PostgresLogo from '../images/postgres.png';
import AirtableLogo from '../images/airtable.png';
import MongodbLogo from '../images/mongodb.png';
import ShopifyLogo from '../images/shopify.png';
import RedshiftLogo from '../images/redshift.png';
import SendgridLogo from '../images/sendgrid.png';
import RestAPILogo from '../images/rest_api.png';
import SnowflakeLogo from '../images/snowflake.png';
import SalesforceLogo from '../images/salesforce.svg';
import GoogleSheetsLogo from '../images/GoogleSheets.svg';
import HubspotLogo from '../images/hubspot.svg';
import FacebookLogo from '../images/facebook.png';
import CloseIOLogo from '../images/close.png';
import DomoLogo from '../images/domo.png';
import QuickbaseLogo from '../images/quickbase.png';
import DynamodbLogo from '../images/dynamodb.png';
import TypeformLogo from '../images/typeform.png';
import WooCommerceLogo from '../images/woocommerce.png';
import DropboxLogo from '../images/dropbox.png';
import HarvestLogo from '../images/harvest-logo.png';
import GoogleBigQueryLogo from '../images/googleBigQuery.svg';
import GoogleSearchConsoleLogo from '../images/Google_G_Logo.svg';
import AddGoogleSheetsIntegration from './AddGoogleSheetsIntegration';
import AddFacebookAdsIntegration from './AddFacebookAdsIntegration';
import AddDropboxIntegration from './AddDropboxIntegration';
import AddNotionIntegration from './AddNotionIntegration';
import NotionLogo from '../images/notion.png';
import AddDatabaseIntegration from './AddDatabaseIntegration';
import AddOAuthIntegration from './AddOAuthIntegration';
import GoogleSheetsGif from '../images/google-auth-gif.gif';
import QuickbooksLogo from '../images/quickbooks.svg';
import KlaviyoLogo from '../images/klaviyo_logo.svg';

export const PASSWORD_PRIVATE_KEY_INPUT_TYPE = 'password_or_private_key';

export default {
  POSTGRES: {
    integration_type: 'POSTGRES',
    name: 'Postgres DB',
    image: PostgresLogo,
    acceptsSSHCredentials: true,
    credentials: [
      { id: 'host', inputTitle: 'Host URL', placeholder: 'abc.amazonaws.com' },
      { id: 'port', inputTitle: 'Port', placeholder: '5432' },
      { id: 'db', inputTitle: 'Database', placeholder: 'my_db' },
      { id: 'username', inputTitle: 'Username', placeholder: 'my_username' },
      {
        type: PASSWORD_PRIVATE_KEY_INPUT_TYPE,
      },
    ],
    renderer: AddDatabaseIntegration,
  },

  GOOGLESHEETS: {
    integration_type: 'GOOGLESHEETS',
    name: 'Google Sheets',
    image: GoogleSheetsLogo,
    oauthPrompt: (
      <>
        <img
          src={GoogleSheetsGif}
          alt="You'll have to check the box that gives permission to 'See, edit, create, and delete only the specific Google Drive files you use with this app.'"
        />
        <p>
          Make sure to check the box that gives permission to 'See, edit,
          create, and delete only the specific Google Drive files you use with
          this app.'
        </p>
        <div>
          Let Intersect Labs get data from and write to the Google Sheets you
          add to your apps:
        </div>
      </>
    ),
    renderer: AddOAuthIntegration,
  },

  HUBSPOT: {
    integration_type: 'HUBSPOT',
    name: 'Hubspot',
    image: HubspotLogo,
    oauthPrompt:
      'Let Intersect Labs get data from and write to the Hubspot account you add to your apps:',
    renderer: AddOAuthIntegration,
  },

  AIRTABLE: {
    integration_type: 'AIRTABLE',
    name: 'Airtable',
    image: AirtableLogo,
    credentials: [
      {
        id: 'api_key',
        inputTitle: 'API Key',
        placeholder: 'API Key',
        isProtected: true,
      },
    ],
  },

  MONGODB: {
    integration_type: 'MONGODB',
    name: 'MongoDB',
    image: MongodbLogo,
    credentials: [
      { id: 'username', inputTitle: 'Username', placeholder: 'my_username' },
      { id: 'password', inputTitle: 'Password', placeholder: 'Sshhhhh...' },
      {
        id: 'connection_string',
        inputTitle: 'Connection String',
        placeholder: 'my_connection_string',
      },
      {
        id: 'database_name',
        inputTitle: 'Database Name',
        placeholder: 'My Database',
      },
      {
        id: 'collection_name',
        inputTitle: 'Collection Name',
        placeholder: 'My Collection',
      },
    ],
  },

  SHOPIFY: {
    integration_type: 'SHOPIFY',
    name: 'Shopify',
    image: ShopifyLogo,
    credentials: [
      { id: 'api_key', inputTitle: 'API Key', placeholder: 'my_api_key' },
      { id: 'password', inputTitle: 'Password', placeholder: 'Sshhhhh...' },
      {
        id: 'example_url',
        inputTitle: 'Store URL',
        placeholder: 'https://mystorename.myshopify.com/',
      },
    ],
  },

  SNOWFLAKE: {
    integration_type: 'SNOWFLAKE',
    name: 'Snowflake',
    image: SnowflakeLogo,
    credentials: [
      { id: 'username', inputTitle: 'Username', placeholder: 'my_username' },
      {
        id: 'account',
        inputTitle: 'Account',
        placeholder: 'abc.us-east-2.aws',
      },
      { id: 'warehouse', inputTitle: 'Warehouse', placeholder: 'my_warehouse' },
      { id: 'db', inputTitle: 'Database', placeholder: 'my_db' },
      { id: 'schema', inputTitle: 'Schema', placeholder: 'my_schema' },
      {
        id: 'password',
        inputTitle: 'Password',
        placeholder: 'Sshhhhh...',
        isProtected: true,
      },
    ],
  },

  REST_API: {
    integration_type: 'REST_API',
    name: 'REST API',
    image: RestAPILogo,
    credentials: [
      {
        id: 'url',
        inputTitle: 'Endpoint URL',
        placeholder: 'https://my_api.com/endpoint',
      },
      {
        id: 'headers',
        inputTitle: 'Request Headers',
        placeholder: '{"token":"my_secret"}',
      },
    ],
  },

  REDSHIFT: {
    integration_type: 'REDSHIFT',
    name: 'Redshift',
    image: RedshiftLogo,
    credentials: [
      {
        id: 'access_key',
        inputTitle: 'Access Key',
        placeholder: 'my_access_key',
      },
      {
        id: 'secret_key',
        inputTitle: 'Secret Key',
        placeholder: 'my_secret_key',
      },
      {
        id: 'cluster_name',
        inputTitle: 'Cluster Name',
        placeholder: 'my_cluster_name',
      },
      {
        id: 'region_name',
        inputTitle: 'Region Name',
        placeholder: 'my_region_name',
      },
      { id: 'user_name', inputTitle: 'User Name', placeholder: 'my_user_name' },
    ],
  },

  SENDGRID: {
    integration_type: 'SENDGRID',
    name: 'Sendgrid',
    image: SendgridLogo,
    credentials: [
      {
        id: 'api_key',
        inputTitle: 'API Key',
        placeholder: 'API Key',
        isProtected: true,
      },
    ],
  },

  SALESFORCE: {
    integration_type: 'SALESFORCE',
    name: 'Salesforce',
    image: SalesforceLogo,
    credentials: [
      { id: 'username', inputTitle: 'User Name', placeholder: 'my_user_name' },
      { id: 'password', inputTitle: 'Password', placeholder: 'Sshhhhh...' },
      {
        id: 'security_token',
        inputTitle: 'Security Token',
        placeholder: 'Sshhhhh...',
      },
    ],
  },

  CLOSEIO: {
    integration_type: 'CLOSEIO',
    name: 'Close CRM',
    image: CloseIOLogo,
    credentials: [
      {
        id: 'api_key',
        inputTitle: 'API Key',
        placeholder: 'api_xxxxx',
        isProtected: true,
      },
    ],
  },

  DOMO: {
    integration_type: 'DOMO',
    name: 'Domo',
    image: DomoLogo,
    credentials: [
      {
        id: 'client_id',
        inputTitle: 'Client ID',
        placeholder: 'api_xxxx',
        isProtected: false,
      },
      {
        id: 'secret',
        inputTitle: 'Secret',
        placeholder: 'secret_xxx',
        isProtected: true,
      },
      {
        id: 'dataset_id',
        inputTitle: 'Dataset ID',
        placeholder: 'data_xxx',
        isProtected: false,
      },
    ],
  },
  QUICKBASE: {
    integration_type: 'QUICKBASE',
    name: 'Quickbase',
    image: QuickbaseLogo,
    credentials: [
      {
        id: 'app_id',
        inputTitle: 'App ID',
        placeholder: 'b6ehc98x',
        isProtected: false,
      },
      {
        id: 'hostname',
        inputTitle: 'Host name',
        placeholder: 'xxxx.quickbase.com',
        isProtected: false,
      },
      {
        id: 'user_token',
        inputTitle: 'User Token',
        placeholder: 'bd9nd0dm_dopdke0d_1_mndjdnasodionkecmm',
        isProtected: true,
      },
    ],
  },
  DYNAMODB: {
    integration_type: 'DYNAMODB',
    name: 'Dynamodb',
    image: DynamodbLogo,
    credentials: [
      {
        id: 'aws_access_key_id',
        inputTitle: 'AWS Access Key ID',
        placeholder: 'AKXXXXXXX',
        isProtected: true,
      },
      {
        id: 'aws_secret_access_key',
        inputTitle: 'AWS Secret Access Key',
        placeholder: 'AKXXXXXXX',
        isProtected: true,
      },
      {
        id: 'region_name',
        inputTitle: 'AWS Region Name',
        placeholder: 'us-east-412',
        isProtected: false,
      },
      {
        id: 'table_name',
        inputTitle: 'Table name',
        placeholder: 'movies',
        isProtected: false,
      },
    ],
  },

  FACEBOOK: {
    integration_type: 'FACEBOOK',
    name: 'Facebook Ads',
    image: FacebookLogo,
    renderer: AddFacebookAdsIntegration,
  },

  DROPBOX: {
    integration_type: 'DROPBOX',
    name: 'Dropbox',
    image: DropboxLogo,
    oauthPrompt:
      'Let Intersect Labs get data from Dropbox when you add a Dropbox block to your apps:',
    renderer: AddOAuthIntegration,
  },

  TYPEFORM: {
    integration_type: 'TYPEFORM',
    name: 'Typeform',
    image: TypeformLogo,
    credentials: [
      {
        id: 'token',
        inputTitle: 'Personal Token',
        placeholder: 'DFASLKNCDALN61781BKJKND',
        isProtected: true,
      },
    ],
    oauthPrompt:
      'Let Intersect Labs get data from Typeform when you add a Typeform block to your apps:',
    renderer: AddOAuthIntegration,
  },

  WOOCOMMERCE: {
    integration_type: 'WOOCOMMERCE',
    name: 'WooCommerce',
    image: WooCommerceLogo,
    credentials: [
      {
        id: 'url',
        inputTitle: 'URL',
        placeholder: 'https://example.com',
      },
      {
        id: 'consumer_key',
        inputTitle: 'Consumer key',
        placeholder: 'ck_xxxxxxxxxxxxxxxx',
      },
      {
        id: 'consumer_secret',
        inputTitle: 'Consumer secret',
        placeholder: 'cs_xxxxxxxxxxxxxxxx',
        isProtected: true,
      },
    ],
  },

  BIGQUERY: {
    integration_type: 'BIGQUERY',
    name: 'BigQuery',
    image: GoogleBigQueryLogo,
    credentials: [
      {
        id: 'service_account_json',
        inputTitle: 'Service Account JSON:',
        placeholder: 'Paste the JSON file you download from Google...',
        needsCodeEditor: true,
        isProtected: true,
      },
    ],
  },

  NOTION: {
    integration_type: 'NOTION',
    name: 'Notion',
    image: NotionLogo,
    oauthPrompt:
      'Let Intersect Labs get data from Notion when you add a Notion block to your apps:',
    renderer: AddOAuthIntegration,
  },

  QUICKBOOKS: {
    integration_type: 'QUICKBOOKS',
    name: 'Quickbooks',
    image: QuickbooksLogo,
    oauthPrompt:
      'Let Intersect Labs get data from Quickboooks when you add a Quickbooks block to your apps:',
    renderer: AddOAuthIntegration,
  },

  HARVEST: {
    integration_type: 'HARVEST',
    name: 'Harvest',
    image: HarvestLogo,
    oauthPrompt:
      'Let Intersect Labs get data from Harvest when you add a Harvest block to your apps',
    renderer: AddOAuthIntegration,
  },

  GOOGLE_SEARCH_CONSOLE: {
    integration_type: 'GOOGLE_SEARCH_CONSOLE',
    name: 'Search Console',
    image: GoogleSearchConsoleLogo,
    credentials: [
      {
        id: 'service_account_json',
        inputTitle: 'Service Account JSON:',
        placeholder: 'Paste the JSON file you download from Google...',
        needsCodeEditor: true,
        isProtected: true,
      },
    ],
  },
  KLAVIYO: {
    integration_type: 'KLAVIYO',
    name: 'Klaviyo',
    image: KlaviyoLogo,
    credentials: [
      { id: 'api_key', inputTitle: 'API Key', placeholder: 'my_api_key' },
    ],
  },
};
